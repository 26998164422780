import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"
/**
 * @param {string} title string
 * @param {} children
 */

const Section = ({
  title,
  children,
  addOns,
  className,
  id,
  isRequired,
  isSectionRequired,
  helper,
}) => {
  const headerClassName = helper
    ? "mb-0 has-text-primary mt-0"
    : "has-text-primary mt-0"

  return (
    <Fragment>
      {title && (
        <div className={classNames(styles["section"])} id={id}>
          <section className={classNames(styles["section__title"])}>
            {addOns?.left}
            <h4 className={headerClassName}>
              {title}{" "}
              {(isRequired === null || isSectionRequired !== true) && (
                <span className="is-italic has-text-weight-normal has-text-grey">
                  (Optional)
                </span>
              )}
            </h4>
            {addOns?.right}
          </section>
          {helper && (
            <p className="mb-1 is-italic has-text-grey is-size-6">{helper}</p>
          )}
        </div>
      )}
      <div className={className || "mt-1 mb-3"}>{children}</div>
    </Fragment>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section
