import { Form, Formik } from "formik"
import React, { Fragment, useContext } from "react"

import FormCheckbox from "elements/Form/FormCheckbox"
import ActionButtons from "elements/ActionButtons"

const SummaryConsent = ({
  state,
  loading,
  dispatch,
  pageContext,
  handleSubmit,
  t,
}) => {
  const TERMS_AND_CONDITIONS_BLURB = `<span>I agree to the <a href="/terms-and-conditions" target="_blank"><b>terms and conditions.</b></a></span>`
  const PILL_SPLIT_BLURB = `<span>I agree that my order will not be served if the prescription provided indicates cutting tablets in half (e.g. Sacubitril-Valsartan 200mg tablet, 1/2 tablet twice a day) and I will visit my doctor to get an updated prescription for whole tablet intake.</span>`

  return (
    <Formik initialValues={state} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-3">
            <FormCheckbox
              name="consentAndAuthorization"
              values={values.consentAndAuthorization}
              options={[TERMS_AND_CONDITIONS_BLURB]}
              onChange={(event) =>
                dispatch({
                  type: "SAVE_AGREE_CONSENT",
                  payload: event.target.checked
                    ? [TERMS_AND_CONDITIONS_BLURB]
                    : [],
                })
              }
              isRequired
            />
            <FormCheckbox
              name="pillSplitAgreement"
              values={values.pillSplitAgreement}
              options={[PILL_SPLIT_BLURB]}
              onChange={(event) =>
                dispatch({
                  type: "SAVE_AGREE_PILLS_SPLIT",
                  payload: event.target.checked ? [PILL_SPLIT_BLURB] : [],
                })
              }
              isRequired
            />
          </div>

          <ActionButtons
            back={{ label: "Back", link: pageContext.backPath }}
            submit={{
              loading,
              label: "Submit",
              disabled:
                values?.consentAndAuthorization?.length <= 0 ||
                values?.pillSplitAgreement?.length <= 0 ||
                state?.medicines?.length === 0 ||
                state?.documents?.length === 0,
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SummaryConsent
